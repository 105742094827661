




















































































































































































import Vue from 'vue'
import ActionFeedbackDisplay from '@/components/ActionFeedbackDisplay.vue'
import EmailListUpload from '@/components/EmailListUpload.vue'
import EmailListEdit from '@/components/EmailListEdit.vue'
import { mapGetters, mapActions } from 'vuex'
import { ActionFeedbackState, EmailListData } from '@/models/interfaces'

import { statesDropdown } from '@/libs/StatesDropdown'

export default Vue.extend({
  name: 'EmailLists',
  components: {
    ActionFeedbackDisplay,
    EmailListEdit,
    EmailListUpload,
  },
  data: (): {
    search: string
    itemsPerPage: number
    footerProps: {
      itemsPerPageOptions: number[]
    }
    dialogEdit: string[] // array of list ids for edit modal component
    dialogFile: string[] // array of list ids for upload modal component
    states: { text: string; value: string }[] // Vuetify dropdown type
    selectedState: string
    activeTag: string
    showTrash: boolean
  } => {
    return {
      search: '',
      itemsPerPage: 20,
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1],
      },
      dialogEdit: [],
      dialogFile: [],
      states: statesDropdown,
      selectedState: '',
      activeTag: '',
      showTrash: false,
    }
  },
  computed: {
    ...mapGetters([
      'getEmailListsOwn',
      'getUser',
      'getUserPermissions',
      'getUserById',
    ]),
    emailLists(): EmailListData[] {
      return this.getEmailListsOwn.filter((list: EmailListData) => {
        if (list.ownerTrashed === undefined) {
          list.ownerTrashed = false
        }
        return list.ownerTrashed === this.showTrash
      })
    },
    emailListsHeader(): { text: string; value: string }[] {
      const headers = [
        { text: 'List Name', value: 'name' },
        { text: 'Price per name', value: 'pricePerName' },
        { text: 'Count', value: 'count' },
        { text: 'Description', value: 'description' },
        { text: 'Tags', value: 'tags' },
        { text: 'Last Updated', value: 'timestampUpdated' },
        { text: 'Published', value: 'published' },
        { text: 'Approved', value: 'approved' },
        { text: '', value: 'actions' },
      ]
      return headers
    },
    selectedStateText(): string {
      const stateObj = this.states.find((state) => {
        return state.value === this.selectedState
      })
      return stateObj ? stateObj.text : 'Unknown state'
    },
    existingTags() {
      const tags: string[] = []
      this.getEmailListsOwn.forEach((emailList: EmailListData) => {
        if (Array.isArray(emailList.tags)) {
          tags.push(...emailList.tags)
        }
      })
      return tags
    },
  },
  watch: {
    getUserPermissions() {
      // see if we need to load (or 'unload') email lists
      this.loadList()
    },
  },
  methods: {
    ...mapActions([
      'bindEmailListsToDb',
      'bindBuyerListsToDb',
      'updateActionFeedback',
      'updateEmailListTrashed',
    ]),
    loadList() {
      if (
        this.getUserPermissions &&
        (this.getUserPermissions.role === 'member' ||
          this.getUserPermissions.role === 'admin')
      ) {
        if (!this.getEmailListsOwn.length) {
          this.bindEmailListsToDb({ viewSet: 'ownLists' })
        }
      }
    },
    tagClickSearch() {
      this.search = this.activeTag
    },
    closeEdit(itemId: string) {
      // set dialogEdit to a new array that excludes the list's itemId
      this.dialogEdit = this.dialogEdit.filter((e) => e !== itemId)
    },
    closeUpload(itemId: string) {
      // set dialogFile to a new array that excludes the list's itemId
      this.dialogFile = this.dialogFile.filter((e) => e !== itemId)
    },
    trashEmailListToggle(emailList: EmailListData) {
      const errors: string[] = []
      // validate and check permissions
      if (emailList.userId !== this.getUser.id) {
        errors.push('Permission denied')
      }
      if (emailList.published === true) {
        errors.push('You must unpublish a list before moving it to trash')
      }
      if (errors.length > 0) {
        this.updateActionFeedback({
          type: 'emailListUpdateTrashed',
          result: {
            state: ActionFeedbackState.Error,
            messages: errors,
          },
        })
        return
      }

      const payload = {
        id: emailList.id,
        ownerTrashed: !emailList.ownerTrashed,
      }
      this.updateEmailListTrashed(payload)
    },
  },
  created() {
    this.loadList()
    // set the edited email list item to default
    // this.resetemailListToEdit()
  },
})
