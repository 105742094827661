






import Vue from 'vue'
import EmailListsOwn from '@/components/EmailListsOwn.vue'

export default Vue.extend({
  name: 'YourLists',
  components: {
    EmailListsOwn,
  },
})
