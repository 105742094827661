var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Your Lists "),_c('v-spacer'),_c('v-text-field',{staticClass:"searchFilter",attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"stateSelectFilter",attrs:{"items":_vm.states,"label":"Filter by state","hide-details":"","prepend-icon":"mdi-map"},model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}}),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.selectedState = ''
        _vm.search = ''
        _vm.activeTag = ''}}},[_vm._v(" Reset ")])],1),_c('v-card-text',[_c('div',{staticClass:"text-right mt-2"},[_c('v-btn',{attrs:{"color":"primary","small":"","outlined":!_vm.showTrash},on:{"click":function($event){_vm.showTrash = !_vm.showTrash}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-trash-can ")]),_vm._v(" Trash ")],1)],1),_c('ActionFeedbackDisplay',{attrs:{"actionFeedbackType":"emailListUpdate"}}),_c('ActionFeedbackDisplay',{attrs:{"actionFeedbackType":"emailListUpdateTrashed"}}),_c('v-data-table',{staticClass:"email-lists",attrs:{"headers":_vm.emailListsHeader,"items":_vm.emailLists,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"no-data-text":"You have no lists for sale","loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [(item.ownerTrashed)?_c('v-icon',[_vm._v("mdi-trash-can")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.count",fn:function(ref){
        var item = ref.item;
return [(item.count > 0 && !item.fileProcessing)?_c('span',[_vm._v(" Total: "+_vm._s(item.count.toLocaleString())),_c('br'),_vm._v(" State / Zip data: "+_vm._s(item.stateCount ? item.stateCount.total.toLocaleString() : 'Unknown')),_c('br'),(_vm.selectedState && item.stateCount[_vm.selectedState])?_c('span',[_vm._v(" "+_vm._s(_vm.selectedStateText)+" names: "+_vm._s(item.stateCount[_vm.selectedState].toLocaleString())+" ")]):_vm._e()]):_vm._e(),(item.fileProcessing)?_c('span',[_vm._v(" Processing... ")]):_vm._e()]}},{key:"item.pricePerName",fn:function(ref){
        var item = ref.item;
return [(item.pricePerName)?_c('span',[_vm._v("$"+_vm._s(item.pricePerName.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]):_vm._e()]}},{key:"item.tags",fn:function(ref){
        var item = ref.item;
return [(item.tags)?_c('v-chip-group',{attrs:{"active-class":"primary","column":""},on:{"change":_vm.tagClickSearch},model:{value:(_vm.activeTag),callback:function ($$v) {_vm.activeTag=$$v},expression:"activeTag"}},_vm._l((item.tags),function(tag){return _c('v-chip',{key:tag,attrs:{"value":tag}},[_vm._v(" "+_vm._s(tag)+" ")])}),1):_vm._e()]}},{key:"item.timestampUpdated",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.timestampUpdated ? item.timestampUpdated.toDate().toDateString() : 'Unknown')+" ")]}},{key:"item.published",fn:function(ref){
        var item = ref.item;
return [(item.published)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])]}},{key:"item.approved",fn:function(ref){
        var item = ref.item;
return [(item.approved)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"600px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 ml-0",attrs:{"color":"accent","small":"","disabled":item.ownerTrashed}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v("Edit ")],1)]}}],null,true),model:{value:(_vm.dialogEdit[item.id]),callback:function ($$v) {_vm.$set(_vm.dialogEdit, item.id, $$v)},expression:"dialogEdit[item.id]"}},[_c('EmailListEdit',{attrs:{"emailListToEdit":item,"existingTags":_vm.existingTags},on:{"close-modal":function($event){return _vm.closeEdit(item.id)}}})],1),_c('v-dialog',{attrs:{"max-width":"800px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2 ml-0",attrs:{"color":"accent","small":"","disabled":item.ownerTrashed}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-cloud-upload ")]),_vm._v("Upload ")],1)]}}],null,true),model:{value:(_vm.dialogFile[item.id]),callback:function ($$v) {_vm.$set(_vm.dialogFile, item.id, $$v)},expression:"dialogFile[item.id]"}},[_c('EmailListUpload',{attrs:{"emailListToEdit":item},on:{"close-modal":function($event){return _vm.closeUpload(item.id)}}})],1),_c('v-btn',{staticClass:"ma-2 ml-0",attrs:{"color":"accent","small":"","outlined":item.ownerTrashed},on:{"click":function($event){return _vm.trashEmailListToggle(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-trash-can ")]),(item.ownerTrashed)?_c('span',[_vm._v("Untrash")]):_c('span',[_vm._v("Trash")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }